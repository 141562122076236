<template>
    <div class="page-editor" >   
        <TemplateDefault v-if="templateType === 'default'" :page="page" :pagePlacementComponent="pagePlacement" />
    </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import usePage from '@common/composable/cms/Page';
// import { useI18n } from 'vue-i18n';

export default {
    name: "PageEditor",
    components: {
        TemplateDefault: defineAsyncComponent(() => import(/* webpackChunkName: "template-default" */'@common/components/cms/templates/TemplateDefault')),
    },
    props: {
    },
    setup(){
        const store = useStore();

        const page = computed(() => store.getters['page_editor/getTempPage']);

        const { templateType } = usePage(page);

        const i18n = useI18n();

        onBeforeRouteLeave(() => {
            const unsaved = store.getters['page_editor/isUnsaved'];
            if(!unsaved){
                return true;
            }
            const answer = window.confirm(i18n.t('controls.confirm_unsaved_changes'));

            if(!answer){
                return false;
            }
        });
        
        const pagePlacement = defineAsyncComponent(() => import(/* webpackChunkName: "page-placement-admin" */'@admin/components/cms/content/PagePlacementAdmin')); 

        return {
            page,
            templateType,
            pagePlacement
        };
    }
}
</script>

<style scoped>
.page-editor{
    max-width: 72rem;
}
.page-editor ::v-deep(ul){
    list-style-type: initial;
}
</style>
